import './Common/Utils/offscreen-canvas-polyfill-browser'
import ReactManager from './ReactManager';

declare global {
    interface Window {
		PRELOADED_STORE: Record<string, any>
		PRELOADED_I18NEXT_STORE: Record<string, any>
		SITE_URL: string; 
		CDN_URL: string; 
		PRODUCT_TMP_URL: string;
		PAGE_URL: string;
		PAGE_ACTION: string;
		PAGE_ROUTE: string;
		THREE_JSON_URL: string;
		THREE_GLB_URL: string;
		IMG_URL: string;
		DATA_URL: string;
		LANG_PREFIX: string;
		LOCALE: string;
		SENTRY_INCLUDE_PATHS: string[],
		APP_GEO_COUNTRYCODE: string;
		APP_USER_ID: string;
		APP_USER_EMAIL: string;
		Products: any;
		ProductDesignView: any;
		aow: any;
		i18next: any;
	}
}

// Initialization
let manager = new ReactManager();
manager.render();

//@ts-ignore
window.reactManager = manager;