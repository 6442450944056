import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from '../../../../config/config.json'

// @ts-ignore
import LegacyModel3DViewer from '../../../js/3d-viewer/Model3DViewer';
import WebsiteStore from "../../WebsiteStore";
import Renderer from "../Renderer/_components/Renderer";
import useLabData from "../_hooks/useLabData";
import { UpdateTexture } from "../../UIData/_actions/DesignLabStoreActions";
import useFixedView from "../../ModelViewer/_hooks/useFixedView";
import { isBrowser } from "browser-or-node";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";

const fps = 60;
const requestAnimFrame = (function(){
    if(!isBrowser) {
        return (callback:() => void) => {
            callback();
        }
    }
    
    return  window.requestAnimationFrame       ||
            //@ts-ignore
            window.webkitRequestAnimationFrame ||
            //@ts-ignore
            window.mozRequestAnimationFrame    ||
            function(callback:() => void){
                window.setTimeout(callback, 1000 / fps);
            };
})();

type Props = {
    productId: number
    width: number,
    height: number,
}

export default function Model3DViewer(props:Props) {
    const dispatch = useAppDispatch();
    const containerRef = useRef();
    const rendererRef = useRef<React.ElementRef<typeof Renderer>>(null);
    const productData = useSelector((state:WebsiteStore) => state.get('appData').get('products').get(String(props.productId)));
    const layers = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('layers'));
    const activeScene = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeScene'));
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));
    const activeSubproduct = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'));
    const autoDesign = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct));
    const activeOptions = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeOptions'));
    const mirrorModes = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('mirrorModes'));
    const textureUpdate = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('textureUpdate'));
    const [fixedView, setFixedView] = useFixedView()
    const labData = useLabData();

    const visibleLayers = useMemo(() => {
        return layers.filter(layer => layer.get('scene') === activeScene && (!activeSubproduct || layer.get('subproduct') === activeSubproduct));
    }, [layers, activeScene, activeSubproduct])

    const modelViewer = useRef<LegacyModel3DViewer|null>(null);
    const modelViewer_renderer = useRef<object|null>(null);
    const textureCanvas_ctx = useRef<CanvasRenderingContext2D>();

    useEffect(() => {
        //Init our own renderer
        if(!isBrowser) {
            return;
        }

        //@ts-ignore
        window.THREE.Cache.enabled = true;

        if(modelViewer_renderer.current) return;

        //@ts-ignore
        modelViewer_renderer.current = new THREE.WebGLRenderer({ 
            antialias: true,
            //preserveDrawingBuffer: true
            alpha: true
        });
    }, []);

    useEffect(() => {
        if(!labData || !labData.variants[activeVariant]) return;

        //TODO: I'm not sure if those types of ids are used anywhere else then the lab. Should consider 
        const subproductLabId = activeSubproduct ? props.productId+'-'+activeSubproduct : props.productId;
        let product = window.Products.getProduct(subproductLabId);
        
        modelViewer.current = new LegacyModel3DViewer(containerRef.current, props.width, props.height, true, () => {
            modelViewer.current.setFixedView('front');
            setFixedView('custom');
            dispatch(UpdateTexture());
        });
        
        modelViewer.current.init(product, labData.slug, activeVariant !== 'default' ? activeVariant : '', false, false, modelViewer_renderer.current);

        // Save for dev
        if (config.ENVIRONMENT !== 'production') {
            //@ts-ignore
            window.mv = modelViewer.current
        }

        let textureCanvas = document.createElement('canvas');
        textureCanvas.width = labData.variants[activeVariant].dimensions.texture.width;
        textureCanvas.height = labData.variants[activeVariant].dimensions.texture.height;
		textureCanvas_ctx.current = textureCanvas.getContext('2d');

        if(labData.transparent_texture){
			textureCanvas_ctx.current.clearRect(0, 0, textureCanvas.width, textureCanvas.height);
		} else {
			textureCanvas_ctx.current.fillStyle = "#ffffff";
			textureCanvas_ctx.current.fillRect(0, 0, textureCanvas.width, textureCanvas.height);
		}

		//Scale texture for kimonos
		//TODO: Remove this and implement code that detects performance
		/*if(this.type == 43 || this.type == 44 || this.type == 66) {
			this.setTextureResolution(0.5);
		}*/

		modelViewer.current.setTextureCanvas(textureCanvas);
        modelViewer.current.texture.needsUpdate = true;
        
        //Make sure we call the viewChange call back when controls are used
        modelViewer.current.controls.addEventListener('start', () => {
            setFixedView('custom');
        })
        
        let paused = false;

        //Lab draw loop
        let now, then = Date.now(), delta, interval = 1000 / fps;
        const draw = function() {
            if(!paused) {
                //Check how much time since last frame
                now = Date.now();
                delta = now - then;

                //Time for a new frame at our locked fps
                if(delta > interval) {
                    /*
                    me.checkLab();

                    try {
                        me.renderAll();
                    } catch(e) {
                        console.log(e);
                        //don't wanna stop the loop because of a rendering error
                    }*/

                    //Call Modelviewer redraw if we have a 3d model
                    if(typeof modelViewer.current !== 'undefined') {
                        modelViewer.current.animate(false);
                    }

                    then = now - (delta % interval);
                }
            }
            requestAnimFrame(draw);
        };

        dispatch(UpdateTexture());
        draw();

        return () => {
            LegacyModel3DViewer.destroy(modelViewer.current);
        }

    }, [labData?.slug, activeVariant]);

    useEffect(() => {
        if(!modelViewer.current) return;

        modelViewer.current.setSize(props.width, props.height);
    }, [props.width, props.height]);

    const updateTexture = useCallback(() => {
        
        if(!rendererRef.current || !textureCanvas_ctx.current || !labData || !labData.variants[activeVariant]) {
            return;
        }

        //Update options
        let productObj = window.Products.getProduct(labData.id);
        activeOptions.forEach((value, option) => {
            productObj.handleOption(modelViewer.current, option, value);
        });
        
        let canvas = rendererRef.current.getCanvas()
        const textureResolution = 1;

        /*if(labData.transparent_texture){
			textureCanvas_ctx.current.clearRect(0, 0, canvas.width, canvas.height);
		} else {
			textureCanvas_ctx.current.fillStyle = "#ffffff";
			textureCanvas_ctx.current.fillRect(0, 0, canvas.width, canvas.height);
		}*/

        textureCanvas_ctx.current.globalCompositeOperation = "source-over";

        const optionObject = activeOptions.toJS();
        const scenes = Object.keys(labData.variants[activeVariant].scenes);
        scenes.forEach(scene => {
            if(modelViewer.current.product.textureCanvasPrepass){
                modelViewer.current.product.textureCanvasPrepass(textureCanvas_ctx.current, scene, textureResolution, optionObject);
            }
        });

        textureCanvas_ctx.current.drawImage(canvas, 0, 0);
        scenes.forEach(scene => {
            if(modelViewer.current.product.textureCanvasPostpass){
                textureCanvas_ctx.current.globalCompositeOperation = "destination-over";
                modelViewer.current.product.textureCanvasPostpass(textureCanvas_ctx.current, modelViewer.current, scene, textureResolution, optionObject);
                textureCanvas_ctx.current.globalCompositeOperation = "source-over";
            }
        });

        modelViewer.current.texture.needsUpdate = true;
    }, [labData, activeOptions]);

    useEffect(() => {
        if(!modelViewer.current || fixedView === 'custom') return;

        modelViewer.current.setFixedView(fixedView);
    }, [fixedView])

    useEffect(() => {
        requestAnimFrame(() => {
            updateTexture();
        })
    }, [textureUpdate, updateTexture, layers, mirrorModes, activeVariant, activeSubproduct, autoDesign]);


    const { subproductRef, subproductRef_labData } = useMemo(() => {
        let tmpSubproductRef = productData.get('labData')?.has_subproducts ? Object.keys(productData.get('labData').subproducts)[0] : undefined;
        let tmpData = undefined;

        if(tmpSubproductRef) {
            tmpData = productData.get('labData').subproducts[tmpSubproductRef].originalData;
        }

        return {
            subproductRef: tmpSubproductRef,
            subproductRef_labData: tmpData,
        }
    }, [productData]);

    return <div ref={containerRef} style={{
        position: 'relative',
        background: 'radial-gradient(circle, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)',
    }}>
        <Renderer
            ref={rendererRef}
            labData={labData}
            subproductRef={subproductRef}
            subproductRef_labData={subproductRef_labData}
            inputType="lab"
            outputType="texture"
        />
    </div>
}