import Konva from 'konva';
import { Layer } from 'konva/lib/Layer';
import { Node } from 'konva/lib/Node';
import { Transformer } from 'konva/lib/shapes/Transformer';
import { Stage } from 'konva/lib/Stage';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer as LayerNode, Line, Rect, Shape, Stage as StageNode, Text, Transformer as TransformerNode } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import KonvaImage from '../../Common/_components/KonvaImage';
import KonvaPatternImage from '../../Common/_components/KonvaPatternImage';
import { EditBackgroundColor, EditLayer, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import DesignLabLayerStore from '../../UIData/_stores/DesignLab/DesignLabLayerStore';
import WebsiteStore from '../../WebsiteStore';
import useLabData from '../_hooks/useLabData';
import useStageDimensions from '../_hooks/useStageDimensions';
import useStageOffset from '../_hooks/useStageOffset';
import useZoom from '../_hooks/useZoom';
import { LAB_TEMPLATE_WIDTH, LAB_TEMPLATE_HEIGHT, LAB_ZOOM_MULTIPLIER } from '../../config';
import { CanvasToLab, InverseZoom } from '../../Common/Utils/ZoomUtils';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';

const GUIDELINE_OFFSET = 10;

type Props = {}

export default function LabStage(props:Props) {
    const dispatch = useAppDispatch();
    const { stageWidth, stageHeight } = useStageDimensions();
    const stageOffset = useStageOffset();
    const zoom = useZoom();

    const activeProductId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeProductId'));
    const activeScene = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeScene'));
    const activeVariant = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeVariant'));
    const activeSubproduct = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('activeSubproduct'));
    const layers = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('layers'));
    const selectedLayerId = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('selectedLayer'));
    const zoomScale = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomScale'));
    const zoomOffsetX = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomOffsetX'));
    const zoomOffsetY = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('zoomOffsetY'));
    const isPanning = useSelector((state:WebsiteStore) => state.get('UIData').get('designLab').get('isPanning'));
    const labData = useLabData();

    const stageRef = useRef<Stage>();
    const overlayRef = useRef<Layer>();
    const transformerRef = useRef<Transformer>();
    const listLayerRefs = useRef(new Map);

    useEffect(() => {
        if(transformerRef.current) {
            if(selectedLayerId && listLayerRefs.current.get(selectedLayerId)) {
                transformerRef.current.nodes([listLayerRefs.current.get(selectedLayerId)]);
            } else {
                transformerRef.current.nodes([]);
            }
        }
    }, [selectedLayerId, layers]);
    
    const visibleLayers = useMemo(() => {
        return layers.filter(layer => layer.get('scene') === activeScene && (!activeSubproduct || layer.get('subproduct') === activeSubproduct));
    }, [layers, activeScene, activeSubproduct])

    const overlaySlug = useMemo(() => {
        if(!labData || !activeScene) return null;

        let slug = labData.id;

        if(activeSubproduct) {
            slug += '-'+activeSubproduct;
        }

        if(activeVariant !== 'default') {
            slug += '-'+activeVariant;
        }

        if(activeScene !== 'main') {
            slug += '-'+activeScene;
        }

        return slug;
    }, [labData, activeScene, activeVariant, activeSubproduct]);

    //Update Background
    useEffect(() => {
        dispatch(EditBackgroundColor(labData?.backgroundColor))
    }, [labData]);

    //Initialize text width/height 
    useEffect(() => {
        layers.forEach(layer => {
            if(layer.get('type') === 'text' && (layer.get('width') === 0 || layer.get('height') === 0)) {
                dispatch(EditLayer(layer.get('id'),  {
                    width: listLayerRefs.current.get(layer.get('id')).getTextWidth(),
                    height: listLayerRefs.current.get(layer.get('id')).height(),
                }))
            }
        })
    }, [layers]);

    const updateLayerFromKonva = useCallback((layerId:string, e:any) => {
        dispatch(EditLayer(layerId,  {
            x: e.target.attrs.x - stageOffset.x,
            y: e.target.attrs.y - stageOffset.y,
            width: e.target.attrs.width,
            height: e.target.attrs.height,
            scaleX: e.target.attrs.scaleX,
            scaleY: e.target.attrs.scaleY,
            rotation: e.target.attrs.rotation,
        }))
    }, [stageOffset]);

    const renderLayer = useCallback((layer:DesignLabLayerStore) => {
        const listNodes = [];

        if(layer.get('type') === 'img' || layer.get('type') === 'pattern') {
            const imageNode = <KonvaImage
                key={layer.get('id')}
                ref={(node) => {
                    if(node) {
                        listLayerRefs.current.set(layer.get('id'), node);

                        if(layer.get('id') === selectedLayerId) {
                            transformerRef.current?.nodes([node])
                        }
                    } else {
                        listLayerRefs.current.delete(layer.get('id'));

                        if(layer.get('id') === selectedLayerId) {
                            transformerRef.current?.nodes([])
                        }
                    }
                }}
                name={'object'}
                draggable={!isPanning}
                onMouseDown={() => {
                    dispatch(SelectLayer(layer.get('id')))
                }}
                onTouchStart={() => {
                    dispatch(SelectLayer(layer.get('id')))
                }}
                onDragStart={() => {
                    setIsDragging(true);
                }}
                onDragMove={(e:any) => {
                    // find possible snapping lines
                    var lineGuideStops = getLineGuideStops(e.target);
                    // find snapping points of current object
                    var itemBounds = getObjectSnappingEdges(e.target);

                    // now find where can we snap current object
                    var guides = getGuides(lineGuideStops, itemBounds);

                    // do nothing if no snapping or we're in pattern mode
                    if (!guides.length || layer.get('patternMode') === 'repeat') {
                        updateLayerFromKonva(selectedLayerId, e);
                        return;
                    }

                    setGuides(guides);

                    var absPos = e.target.absolutePosition();
                    // now force object position
                    guides.forEach((lg) => {
                        switch (lg.snap) {
                            case 'start': {
                                switch (lg.orientation) {
                                    case 'V':
                                        absPos.x = lg.lineGuide + lg.offset;
                                        break;

                                    case 'H': 
                                        absPos.y = lg.lineGuide + lg.offset;
                                        break;

                                }
                                break;
                            }
                            case 'center': {
                                switch (lg.orientation) {
                                    case 'V':
                                        absPos.x = lg.lineGuide + lg.offset;
                                        break;

                                    case 'H':
                                        absPos.y = lg.lineGuide + lg.offset;
                                        break;

                                }
                                break;
                            }
                            case 'end': {
                                switch (lg.orientation) {
                                    case 'V':
                                        absPos.x = lg.lineGuide + lg.offset;
                                        break;
                                    case 'H':
                                        absPos.y = lg.lineGuide + lg.offset;
                                        break;
                                }
                                break;
                            }
                        }
                    });
                    e.target.absolutePosition(absPos);
                    updateLayerFromKonva(selectedLayerId, e);
                }}
                onDragEnd={(e:any) => {
                    setIsDragging(false);
                    updateLayerFromKonva(layer.get('id'), e);
                }}
                onTransform={(e:any) => {
                    updateLayerFromKonva(layer.get('id'), e);
                }}
                src={layer.get('src')}
                rotation={layer.get('rotation')}
                width={layer.get('width')}
                height={layer.get('height')}
                offsetX={layer.get('width') / 2}
                offsetY={layer.get('height') / 2}
                x={layer.get('x') + stageOffset.x}
                y={layer.get('y') + stageOffset.y}
                scaleX={layer.get('scaleX')}
                scaleY={layer.get('scaleY')}
            />;

            if(layer.get('patternMode') !== 'none') {
                const maxLabSide = Math.max(LAB_TEMPLATE_WIDTH, LAB_TEMPLATE_HEIGHT);

                listNodes.push(<KonvaPatternImage
                    key={layer.get('id')+'-pattern'}
                    x={stageOffset.x+layer.get('x')}
                    y={stageOffset.y+layer.get('y')}
                    width={maxLabSide*2}
                    height={maxLabSide*2}
                    imageWidth={layer.get('width')}
                    imageHeight={layer.get('height')}
                    fillPatternX={maxLabSide}
                    fillPatternY={maxLabSide}
                    fillPatternOffsetX={layer.get('width')/2}
                    fillPatternOffsetY={layer.get('height')/2}
                    src={layer.get('src')}
                    fillPatternScaleX={layer.get('scaleX')}
                    fillPatternScaleY={layer.get('scaleY')}
                    offsetX={maxLabSide}
                    offsetY={maxLabSide}
                    rotation={layer.get('rotation')}
                    patternMode={layer.get('patternMode')}
                    listening={false}
                />);

                if(layer.get('id') !== selectedLayerId) {
                    const dashLength = 4*(1/zoomScale);
                    listNodes.push(<Line
                        key={layer.get('id')+'-line1'}
                        points={[
                            0, 0,
                            layer.get('width')*layer.get('scaleX'), 0,
                            layer.get('width')*layer.get('scaleX'), layer.get('height')*layer.get('scaleY'),
                            0, layer.get('height')*layer.get('scaleY'),
                            0, 0,
                        ]}
                        stroke="#ffffff"
                        strokeWidth={2*(1/zoomScale)}
                        dash={[dashLength, dashLength, 0]}
                        x={layer.get('x') + stageOffset.x}
                        y={layer.get('y') + stageOffset.y}
                        offsetX={(layer.get('width')*layer.get('scaleX')) / 2}
                        offsetY={(layer.get('height')*layer.get('scaleY')) / 2}
                        rotation={layer.get('rotation')}
                        listening={false}
                    />)

                    listNodes.push(<Line
                        key={layer.get('id')+'-line2'}
                        points={[
                            0, 0,
                            layer.get('width')*layer.get('scaleX'), 0,
                            layer.get('width')*layer.get('scaleX'), layer.get('height')*layer.get('scaleY'),
                            0, layer.get('height')*layer.get('scaleY'),
                            0, 0,
                        ]}
                        stroke="#000000"
                        strokeWidth={2*(1/zoomScale)}
                        dash={[0, dashLength, dashLength]}
                        x={layer.get('x') + stageOffset.x}
                        y={layer.get('y') + stageOffset.y}
                        offsetX={(layer.get('width')*layer.get('scaleX')) / 2}
                        offsetY={(layer.get('height')*layer.get('scaleY')) / 2}
                        rotation={layer.get('rotation')}
                        listening={false}
                    />)
                }
            }

            listNodes.push(imageNode);
        } else if(layer.get('type') === 'text') {
            const textNode = <Text
                key={layer.get('id')}
                ref={(node) => {
                    if(node) {
                        listLayerRefs.current.set(layer.get('id'), node);
                        
                        if(layer.get('id') === selectedLayerId) {
                            transformerRef.current?.nodes([node])
                        }
                    } else {
                        listLayerRefs.current.delete(layer.get('id'));

                        if(layer.get('id') === selectedLayerId) {
                            transformerRef.current?.nodes([])
                        }
                    }
                }}
                name={'object'}
                draggable={!isPanning}
                onMouseDown={() => {
                    dispatch(SelectLayer(layer.get('id')))
                }}
                onTouchStart={() => {
                    dispatch(SelectLayer(layer.get('id')))
                }}
                onDragStart={() => {
                    setIsDragging(true);
                }}
                onDragMove={(e:any) => {
                    // find possible snapping lines
                    var lineGuideStops = getLineGuideStops(e.target);
                    // find snapping points of current object
                    var itemBounds = getObjectSnappingEdges(e.target);

                    // now find where can we snap current object
                    var guides = getGuides(lineGuideStops, itemBounds);

                    // do nothing if no snapping
                    if (!guides.length) {
                        updateLayerFromKonva(selectedLayerId, e);
                        return;
                    }

                    setGuides(guides);

                    var absPos = e.target.absolutePosition();
                    // now force object position
                    guides.forEach((lg) => {
                        switch (lg.snap) {
                            case 'start': {
                            switch (lg.orientation) {
                                case 'V': {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                                }
                                case 'H': {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                                }
                            }
                            break;
                            }
                            case 'center': {
                            switch (lg.orientation) {
                                case 'V': {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                                }
                                case 'H': {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                                }
                            }
                            break;
                            }
                            case 'end': {
                            switch (lg.orientation) {
                                case 'V': {
                                absPos.x = lg.lineGuide + lg.offset;
                                break;
                                }
                                case 'H': {
                                absPos.y = lg.lineGuide + lg.offset;
                                break;
                                }
                            }
                            break;
                            }
                        }
                    });
                    e.target.absolutePosition(absPos);
                    updateLayerFromKonva(selectedLayerId, e);
                }}
                onDragEnd={(e:any) => {
                    setIsDragging(false);
                    updateLayerFromKonva(layer.get('id'), e);
                }}
                onTransform={(e) => {
                    dispatch(EditLayer(layer.get('id'),  {
                        x: e.target.attrs.x - stageOffset.x,
                        y: e.target.attrs.y - stageOffset.y,
                        width: listLayerRefs.current.get(layer.get('id')).getTextWidth(),
                        height: listLayerRefs.current.get(layer.get('id')).height(),
                        scaleX: e.target.attrs.scaleX,
                        scaleY: e.target.attrs.scaleY,
                        rotation: e.target.attrs.rotation,
                    }))
                }}
                text={layer.get('text')}
                fontSize={50}
                fontFamily={layer.get('font')}
                fill={layer.get('color')}
                fillEnabled={true}
                opacity={layer.get('width') === 0 && layer.get('height') === 0 ? 0 : 1}
                //stroke="#00ff00"
                //strokeWidth={5}
                //strokeEnabled={true}
                rotation={layer.get('rotation')}
                width={layer.get('width') !== 0 ? layer.get('width') : undefined}
                height={layer.get('height') !== 0 ? layer.get('height') : undefined}
                offsetX={layer.get('width') / 2}
                offsetY={layer.get('height') / 2}
                x={layer.get('x') + stageOffset.x}
                y={layer.get('y') + stageOffset.y}
                scaleX={layer.get('scaleX')}
                scaleY={layer.get('scaleY')}
            />;
            listNodes.push(textNode);
        }

        return listNodes;
    }, [selectedLayerId, isPanning, stageOffset, zoomScale]);

    // were can we snap our objects?
    const getLineGuideStops = useCallback((skipShape:Node) => {
        let vertical:number[] = [];
        let horizontal:number[] = [];

        //Snap to Edges and Centers of each object
        stageRef.current.find('.object').forEach((guideItem) => {
          if (guideItem === skipShape) {
            return;
          }
          var box = guideItem.getClientRect();

          //Snap to all edges of shape
          vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
          horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
        });

        return {
          vertical: vertical.flat(),
          horizontal: horizontal.flat(),
        };
    }, []);

      //Get edges and center of objet to snap to
    const getObjectSnappingEdges = useCallback((node:Node) => {
        var box = node.getClientRect();
        var absPos = node.absolutePosition();

        return {
          vertical: [
            {
              guide: box.x,
              offset: absPos.x - box.x,
              snap: 'start',
            },
            {
              guide: box.x + box.width / 2,
              offset: absPos.x - box.x - box.width / 2,
              snap: 'center',
            },
            {
              guide: box.x + box.width,
              offset: absPos.x - box.x - box.width,
              snap: 'end',
            },
          ],
          horizontal: [
            {
              guide: box.y,
              offset: absPos.y - box.y,
              snap: 'start',
            },
            {
              guide: box.y + box.height / 2,
              offset: absPos.y - box.y - box.height / 2,
              snap: 'center',
            },
            {
              guide: box.y + box.height,
              offset: absPos.y - box.y - box.height,
              snap: 'end',
            },
          ],
        };
    }, []);

    const getGuides = useCallback((
        lineGuideStops:{ vertical: number[], horizontal: number[]}, 
        itemBounds:{ vertical: { guide:number, offset: number, snap: string }[], horizontal: { guide:number, offset: number, snap: string }[] }
    ) => {
        var resultV:{
            lineGuide: number,
            diff: number,
            snap: string,
            offset: number,
        }[] = [];
        var resultH:{
            lineGuide: number,
            diff: number,
            snap: string,
            offset: number,
        }[] = [];

        lineGuideStops.vertical.forEach((lineGuide) => {
          itemBounds.vertical.forEach((itemBound) => {
            var diff = Math.abs(lineGuide - itemBound.guide);

            //If distance between guideline and object snap point is within offset, add it
            if (diff < GUIDELINE_OFFSET) {
              resultV.push({
                lineGuide: lineGuide,
                diff: diff,
                snap: itemBound.snap,
                offset: itemBound.offset,
              });
            }
          });
        });

        lineGuideStops.horizontal.forEach((lineGuide) => {
          itemBounds.horizontal.forEach((itemBound) => {
            var diff = Math.abs(lineGuide - itemBound.guide);
            if (diff < GUIDELINE_OFFSET) {
              resultH.push({
                lineGuide: lineGuide,
                diff: diff,
                snap: itemBound.snap,
                offset: itemBound.offset,
              });
            }
          });
        });

        var guides = [];

        //Find closest snap point
        var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
        var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
        if (minV) {
          guides.push({
            lineGuide: minV.lineGuide,
            offset: minV.offset,
            orientation: 'V',
            snap: minV.snap,
          });
        }
        if (minH) {
          guides.push({
            lineGuide: minH.lineGuide,
            offset: minH.offset,
            orientation: 'H',
            snap: minH.snap,
          });
        }
        return guides;
    }, []);

    const [guides,setGuides] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [hasStartedPanning, setHasStartedPanning] = useState(false);

    useEffect(() => {
        if(!isPanning) {
            setHasStartedPanning(false);
        }
    }, [isPanning])

    return <StageNode 
        ref={stageRef}
        draggable={isPanning}
        width={stageWidth} 
        height={stageHeight}
        scaleX={zoomScale}
        scaleY={zoomScale}
        offsetX={zoomOffsetX}
        offsetY={zoomOffsetY}
        onWheel={(e) => {
            e.evt.preventDefault();
            if(!stageRef.current) return;

            const pointer = stageRef.current.getPointerPosition();
            
            let wheel = - Math.round(e.evt.deltaY) / LAB_ZOOM_MULTIPLIER;

            zoom(wheel, {x: pointer.x, y: pointer.y});
        }}
        onDragStart={(e) => {
            if(!isPanning) return;
            setHasStartedPanning(true);
        }}
        onDragEnd={(e) => {
            if(!isPanning) return;
            setHasStartedPanning(false);
        }}
        onDragMove={(e) => {
            if(!isPanning) return;
            setHasStartedPanning(true);

            // Prevent the konva pan it's bad
            stageRef.current.x(0);
            stageRef.current.y(0);

            var offset = {x: zoomOffsetX, y: zoomOffsetY}

            zoom(0, InverseZoom({
                x: offset.x - e.evt.movementX / zoomScale,
                y: offset.y - e.evt.movementY / zoomScale,
            }, offset, zoomScale));
        }}
        style={{
            background: "#ededed",
            borderRadius: "10px",
            display: "inline-block",
            overflow: "hidden",
            cursor: isPanning ? (hasStartedPanning ? 'grabbing' : 'grab') : 'default',
        }}
    >
        <LayerNode>
            <Rect
                width={Math.max(LAB_TEMPLATE_WIDTH, stageWidth/Math.min(1, zoomScale))}
                height={Math.max(LAB_TEMPLATE_HEIGHT, stageHeight/Math.min(1, zoomScale))}
                x={Math.min(stageOffset.x, 0, -(stageWidth/zoomScale - stageWidth)/2)}
                y={Math.min(stageOffset.y, 0, -(stageHeight/zoomScale - stageHeight)/2)}
                fill="#fff"
                onMouseDown={() => {
                    dispatch(SelectLayer(null));
                }}
                onTouchStart={() => {
                    dispatch(SelectLayer(null));
                }}
            />
            { visibleLayers.valueSeq().map(renderLayer) }
        </LayerNode>
        <LayerNode
            ref={overlayRef}
        >
            <Shape
                width={stageWidth}
                height={stageHeight}
                listening={false}
                sceneFunc={(ctx) => {
                    let rectBox = {
                        x: 0,
                        y: 0,
                        width: stageWidth,
                        height: stageHeight,
                    };
                    
                    ctx.fillStyle = 'rgba(255,255,255,1)';
                    ctx.strokeStyle = 'rgba(255,0,0,0)';
                    ctx.fillRect(rectBox.x, rectBox.y, rectBox.width, rectBox.height);
                    
                    ctx.clearRect(stageOffset.x, stageOffset.y, LAB_TEMPLATE_WIDTH, LAB_TEMPLATE_HEIGHT)

                    if(overlayRef.current) {
                        overlayRef.current.canvas._canvas.style.opacity = '0.6';
                    }
                }}
            />
            { overlaySlug ? <KonvaImage
                src={require('@resources/img/create/overlays/svg/'+overlaySlug+'.svg?url')}     
                width={LAB_TEMPLATE_WIDTH}
                height={LAB_TEMPLATE_HEIGHT}
                x={stageOffset.x}
                y={stageOffset.y}
                listening={false}
                disableCache
            /> : null }
        </LayerNode>
        { isDragging && <LayerNode>
            { guides.map((lg, index) => {
                return <Line
                    key={index}
                    points={lg.orientation === 'H' ? [-6000, 0, 6000, 0] : [0, -6000, 0, 6000]}
                    stroke="#ea008a"
                    strokeWidth={1*(1/zoomScale)}
                    dash={[4*(1/zoomScale), 6*(1/zoomScale)]}
                    x={lg.orientation === 'H' ? 0 : lg.lineGuide+zoomOffsetX}
                    y={lg.orientation === 'H' ? lg.lineGuide+zoomOffsetY : 0}
                />
            }) }
        </LayerNode> }
        <LayerNode>
            <TransformerNode
                ref={transformerRef}
                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
                borderStroke={"#000"}
                borderStrokeWidth={2}
                borderDash={[4]}
                anchorSize={16}
                anchorCornerRadius={2}
                anchorStroke={"#000"}
                anchorStrokeWidth={2}
                keepRatio={true}
                flipEnabled={false}
                useSingleNodeRotation
                centeredScaling
                opacity={layers?.get(selectedLayerId) && layers.get(selectedLayerId).get('type') === 'text' && layers.get(selectedLayerId).get('width') === 0 && layers.get(selectedLayerId).get('height') === 0 ? 0 : 1}
                boundBoxFunc={(oldBox, newBox) => {
                    if(newBox.width/zoomScale < 20 || newBox.height/zoomScale < 20) {
                        return oldBox;
                    }

                    return newBox;
                }}
                /*onTransform={(e) => {
                    dispatch(EditLayer(selectedLayerId,  {
                        x: e.target.attrs.x - stageOffset.x,
                        y: e.target.attrs.y - stageOffset.y,
                        width: e.target.attrs.width,
                        height: e.target.attrs.height,
                        scaleX: e.target.attrs.scaleX,
                        scaleY: e.target.attrs.scaleY,
                        rotation: e.target.attrs.rotation,
                    }))
                }}
                onTransformEnd={(e) => {
                    dispatch(EditLayer(selectedLayerId,  {
                        x: e.target.attrs.x - stageOffset.x,
                        y: e.target.attrs.y - stageOffset.y,
                        width: e.target.attrs.width,
                        height: e.target.attrs.height,
                        scaleX: e.target.attrs.scaleX,
                        scaleY: e.target.attrs.scaleY,
                        rotation: e.target.attrs.rotation,
                    }))
                }}*/
            />
        </LayerNode>
    </StageNode>
}