import { useCallback, useMemo } from 'react'
import * as React from 'react'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { Alert, Collapse, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UpdateCartShippingType } from '../../UserData/_actions/UserCartActions'
import { CartStack } from '../../Cart/CartContent/CartStack'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { type ShippingType } from '../../UserData/_stores/UserCartStore'

interface Props {
  cart: UserCartStore
}

export default function CheckoutShippingOptions(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const isInternationalNoTracking = useMemo(() => {
    const shippingType = props.cart.get('availableShippingTypes').get(props.cart.get('id_shipping_type'))

    return !['Canada', 'United States'].includes(props.cart.get('country')) && shippingType?.get('slug') === 'intl-untracked'
  }, [props.cart])

  const sortedShippingTypes = useMemo(() => {
    return props.cart.get('availableShippingTypes').sort((a, b) => a.get('price').toDinero().greaterThan(b.get('price').toDinero()) ? 1 : -1)
  }, [props.cart])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const fd = new FormData()
    fd.append('id_cart', String(props.cart.get('id')))
    fd.append('id', String(e.target.value))

    dispatch(UpdateCartShippingType(fd))
  }, [props.cart])

  const renderShippingType = useCallback((type: ShippingType) => {
    return <FormControlLabel
      key={type.get('id')}
      value={type.get('id')}
      label={<div>
        <Typography variant="body1" component="p">{type.get('name')}
          - <strong>{type.get('price').toDinero().toFormat()}</strong></Typography>
        <Typography variant="caption" component="p">{type.get('description')}</Typography>
      </div>}
      control={<Radio />}
      disabled={cartLoader}
    />
  }, [cartLoader])

  return <CartStack spacing={2}>
    <Typography variant="h3" color="primary">{t('Select Shipping option')}</Typography>

    {props.cart.get('availableShippingTypes').count() > 0 ? <>
      <RadioGroup
        row
        value={props.cart.get('id_shipping_type')}
        onChange={onChange}
      >
        {sortedShippingTypes.valueSeq().map(renderShippingType)}
      </RadioGroup>
      <Collapse in={isInternationalNoTracking}>
        <Alert severity="info">{t('We strongly advise adding tracking as international orders may take 10+ days to arrive.')}</Alert>
      </Collapse>
    </> : <>
      <Alert severity="info">{t('Fill out your shipping address before selecting your shipping option.')}</Alert>
    </>}
  </CartStack>
}
