import { useCallback } from "react";
import { useSelector } from "react-redux";
import WebsiteStore from "../../../WebsiteStore";
import { Layer as LayerNode, Rect, Stage as StageNode, Text, Image as ImageNode, Group as GroupNode } from "react-konva";
import KonvaPatternImage from "../../../Common/_components/KonvaPatternImage";
import KonvaImage from "../../../Common/_components/KonvaImage";
import { isBrowser, isNode } from "browser-or-node";
import PartRenderer from "./PartRenderer";

type Props = {
    subproduct: string|null,
    subproductRef?: string,
    scene: string,
    sceneData?: Record<string,any>,
    subproductRef_sceneData?: Record<string,any>,
    inputType: 'lab',
    outputType: 'texture'|'render',
    transparentTexture?: boolean,
    labData: Record<string, any>,
}

export default function SceneRenderer(props:Props) {
    const parts = Object.keys(props.sceneData.parts);

    return <>
        { parts.map(part => {
            return <PartRenderer
                key={part}
                subproduct={props.subproduct}
                subproductRef={props.subproductRef}
                scene={props.scene}
                part={part}
                partData={props.sceneData.parts[part]}
                subproductRef_partData={props.subproductRef_sceneData?.parts[part]}
                inputType={props.inputType}
                outputType={props.outputType}
                transparentTexture={props.transparentTexture}
                labData={props.labData}
            />
        }) }
    </>
}