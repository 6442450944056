import { Texture } from 'three'
import Immutable from 'immutable'
import BodyconDress from './Products/BodyconDress'
import FlareDress from './Products/FlareDress'
import Leggings from './Products/Leggings'
import FlareSkirt from './Products/FlareSkirt'
import FittedSkirt from './Products/FittedSkirt'
import AthleticCropTop from './Products/AthleticCropTop'
import PermanentSticker from './Products/PermanentSticker'
import VeganLeatherCrossbodyPurse from './Products/VeganLeatherCrossbodyPurse'
import VeganLeatherMakeupBag from './Products/VeganLeatherMakeupBag'
import VeganLeatherToteBag from './Products/VeganLeatherToteBag'
import YogaShorts from './Products/YogaShorts'
import ZipperCarryAll from './Products/ZipperCarryAll'
import MakeupBag from './Products/MakeupBag'
import PencilCase from './Products/PencilCase'
import { useMemo } from 'react'
import VeganLeatherBackpack from './Products/VeganLeatherBackpack'
import DrawstringBag from './Products/DrawstringBag'
import StainlessSteelTumbler from './Products/StainlessSteelTumbler'
import UnisexPoloShirt from './Products/UnisexPoloShirt'
import FittedPoloShirt from './Products/FittedPoloShirt'

export interface ProductModelProps {
    viewerId: string,
    options: Immutable.Map<string,string>
}

type Props = {
    viewerId: string,
    productId: number,
    options : Immutable.Map<string, string>,
}

export default function ModelViewerProduct(props: Props) {    
    const productModelProps = useMemo(() => {
        return {
            viewerId: props.viewerId,
            options: props.options,
        }
    }, [props.viewerId, props.options])

    switch(props.productId) {
        case 7: return <PencilCase {...productModelProps}/>
        case 12: return <Leggings {...productModelProps}/>
        case 17: return <FittedSkirt {...productModelProps}/>
        case 20: return <FlareSkirt {...productModelProps}/>
        case 35: return <BodyconDress {...productModelProps}/>
        case 36: return <FlareDress {...productModelProps}/>
        case 45: return <MakeupBag {...productModelProps}/>
        case 61: return <AthleticCropTop {...productModelProps}/>
        case 73: return <ZipperCarryAll {...productModelProps}/>
        case 118: return <VeganLeatherCrossbodyPurse {...productModelProps}/>
        case 119: return <VeganLeatherMakeupBag {...productModelProps}/>
        case 120: return <VeganLeatherToteBag {...productModelProps}/>
        case 121: return <YogaShorts {...productModelProps}/>
        case 122: return <PermanentSticker {...productModelProps}/>
        case 123: return <VeganLeatherBackpack {...productModelProps}/>
        case 124: return <DrawstringBag {...productModelProps}/>
        case 125: return <StainlessSteelTumbler {...productModelProps}/>
        case 126: return <UnisexPoloShirt {...productModelProps}/>
        case 127: return <FittedPoloShirt {...productModelProps}/>
    }

    return null
}